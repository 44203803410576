<template>
  <div>
    <CRow>
      <CCol lg="12">
        <CCard>
          <CCardHeader>
            <slot name="header">
              <strong>Standort bearbeiten</strong> #{{ $route.params.id }}
            </slot>
          </CCardHeader>
          <CCardBody>
            <KensingtonLocationForm
                v-if="ready"
                @submit="editKensingtonLocation"
                :is-edit="true"
                :passedData="kensingtonLocation"
                :processing="processing"
                btn-text="Änderungen speichern"/>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import axios from 'axios'
import KensingtonLocationForm from "@/plugin/kensington/KensingtonLocationForm";

export default {
  name: 'KensingtonLocationEdit',
  components: { KensingtonLocationForm },
  data() {
    return {
      ready: false,
      kensingtonLocation: null,
      processing: false
    }
  },
  created() {
    axios({ method: 'GET', url: `/kensington/location/${this.$route.params.id}`, baseURL: process.env.VUE_APP_PLUGIN_BASE_URL })
        .then(response => {
          this.kensingtonLocation = response.data
          this.ready = true
        }).catch(err => console.error(err))
  },
  beforeRouteLeave (to, from, next) {
    if (to.params.saved) {
      next()
      return
    }
    this.$modal.showModal(
        'default',
        'Editor wirklich schließen?',
        [
          `Es gibt noch Änderungen im Editor, die nicht gespeichert wurden und mit dieser Aktion verloren gehen.`
        ],
        () => {
          next()
        },
        () => {
          next(false)
        },
        { btnCancel: 'abbrechen', btnConfirm: 'Trotzdem schließen', btnConfirmColor: 'danger' }
    )
  },
  methods: {
    editKensingtonLocation(data) {
      this.processing = true
      axios({ method: 'PUT', url: `/kensington/location/${this.$route.params.id}`, data, baseURL: process.env.VUE_APP_PLUGIN_BASE_URL })
          .then(() => {
            this.$router.push({name: 'KensingtonLocations', params: { saved: '1' }})
            this.$toaster.makeToast('Erfolgreich!', "<b>Änderungen wurden gespeichert</b>")
          })
          .catch(err => {
            if (err.response) {
              this.$toaster.makeToast('Oops!', '<b>' + err.response.data.code + '</b><br>' + err.response.data.message)
            } else {
              this.$toaster.makeToast('Fehler!', err.message)
            }
          })
          .finally(() => {
            this.processing = false
          })
    }
  }
}
</script>
